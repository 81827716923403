<template>
  <div
    :id="'kt_customer_view_payment_method_' + item.id"
    class="card-body pt-0"
  >
    <div class="py-0" data-kt-customer-payment-method="row">
      <div class="py-3 d-flex flex-stack flex-wrap">
        <div
          class="d-flex align-items-center rotate"
          :href="'#kt_customer_view_payment_method_' + item.id"
          role="button"
          aria-expanded="false"
          :aria-controls="'kt_customer_view_payment_method_' + item.id"
        >
          <span class="svg-icon svg-icon-2x me-4"
            ><svg
              fill="none"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                fill="black"
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                opacity="0.3"
                d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                fill="black"
              ></path></svg
          ></span>
          <div class="me-3">
            <div class="d-flex align-items-center">
              <div class="text-gray-800 fw-bolder">{{ item.name }}</div>
              <div class="badge badge-light-primary ms-5">
                {{ item.status }}
              </div>
            </div>
            <div class="text-muted">Added {{ item.created_at }}</div>
          </div>
        </div>
        <div class="d-flex my-3 ms-9">
          <a
            href=""
            @click.prevent="downloadCsv(item.id)"
            class="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
            data-bs-toggle="tooltip"
            title=""
            data-kt-customer-payment-method="delete"
            data-bs-original-title="Delete"
            ><i class="fas fa-download"></i
          ></a>
          <button
            class="btn btn-icon btn-active-light-primary w-30px h-30px"
            data-bs-toggle="tooltip"
            title=""
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-bs-original-title="More Options"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
      <div
        :id="'kt_customer_view_payment_method_' + item.id"
        class="fs-6 ps-10"
        data-bs-parent="'#kt_customer_view_payment_method_' + item.id"
      >
        <div class="d-flex flex-wrap py-5">
          <div class="flex-equal me-5">
            <table class="table table-flush fw-bold gy-1">
              <tbody>
                <tr>
                  <td class="text-muted min-w-125px w-125px">Job ID</td>
                  <td class="text-gray-800">{{ item.id }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px w-125px">Status</td>
                  <td class="text-gray-800">{{ item.status }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px w-125px">Records Found</td>
                  <td class="text-gray-800">{{ item.total_emails }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px w-125px">
                    Billable Records
                  </td>
                  <td class="text-gray-800">
                    {{ item.total_emails }}
                  </td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px w-125px">
                    Duplicate Emails
                  </td>
                  <td class="text-gray-800">{{ item.duplicate_emails }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px w-125px">Query Time</td>
                  <td class="text-gray-800">
                    {{ this.formatSeconds(item.timespend) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex-equal">
            <div class="" style="max-width: 100%; position: relative">
              <DonutChart :item="item" />
              <!--              <canvas
                :id="'doughnut-chart-' + item.id"
                width="553"
                height="150"
                style="
                  max-width: 100%;
                  max-height: 100%;
                  display: block;
                  box-sizing: border-box;
                  height: 150px;
                  width: 553px;
                "
              ></canvas>-->
            </div>
          </div>
          <div class="flex-equal">
            <table class="table table-flush fw-bold gy-1">
              <tbody>
                <tr>
                  <td class="text-muted min-w-125px">
                    <span class="bullet bullet-round h-10px bg-success"></span>
                    Valid Emails
                  </td>
                  <td class="text-gray-800">{{ item.valid_emails }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px">
                    <span class="bullet bullet-round h-10px bg-warning"></span>
                    Accept All Emails
                  </td>
                  <td class="text-gray-800">{{ item.accept_all_emails }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px">
                    <span class="bullet bullet-round h-10px bg-grey"></span>
                    Unknown Emails
                  </td>
                  <td class="text-gray-800">{{ item.unknown_emails }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px">
                    <span class="bullet bullet-round h-10px bg-info"></span>
                    Disposable Emails
                  </td>
                  <td class="text-gray-800">{{ item.disposable_emails }}</td>
                </tr>
                <tr>
                  <td class="text-muted min-w-125px">
                    <span class="bullet bullet-round h-10px bg-danger"></span>
                    Invalid Emails
                  </td>
                  <td class="text-gray-800">{{ item.invalid_emails }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-dashed"></div>
  </div>
</template>

<script>
import DonutChart from "@/components/widgets/charts/DonutChart.vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";

export default {
  name: "EmailVerificationRequest",
  components: { DonutChart },
  props: ["item"],
  data() {
    return {
      /*      id: 1,
      name: "TestList",
      status: "completed",
      total_emails: 100,
      complete_emails: 99,
      duplicate_emails: 1,
      valid_emails: 78,
      accept_all_emails: 3,
      disposable_emails: 4,
      unknown_emails: 10,
      invalid_emails: 5,
      file_location: "notfound",
      created_at: "2023-03-16 22:47:17"*/
    };
  },
  methods: {
    formatSeconds(seconds) {
      const duration = moment.duration(seconds, "seconds");
      return moment.utc(duration.asMilliseconds()).format("H:mm:ss");
    },
    downloadCsv(id) {
      ApiService.get("my-email-verification-lists-v2-download/" + id).then(
        function (response) {
          const contentDisposition = response.headers["content-disposition"];
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          let filename;
          if (filenameMatch && filenameMatch.length === 2) {
            filename = filenameMatch[1];
          } else {
            filename = 'data.csv';
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      );
    },
  },
};
</script>
