<template>
  <div class="card mt-6">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <h4>Email Verification Lists</h4>
      </div>
      <!--begin::Card title-->
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-sm btn-flex btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_add_email_list"
        >
          <span class="svg-icon svg-icon-3">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                xmlns="http://www.w3.org/2000/svg"
                opacity="0.3"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="5"
                fill="black"
              ></rect>
              <rect
                xmlns="http://www.w3.org/2000/svg"
                x="10.8891"
                y="17.8033"
                width="12"
                height="2"
                rx="1"
                transform="rotate(-90 10.8891 17.8033)"
                fill="black"
              ></rect>
              <rect
                xmlns="http://www.w3.org/2000/svg"
                x="6.01041"
                y="10.9247"
                width="12"
                height="2"
                rx="1"
                fill="black"
              ></rect>
            </svg>
          </span>
          Add new list</a
        >
      </div>
    </div>
    <div class="card-body pt-0">
      <EmailVerificationRequest
        v-for="(item, index) in emailVerificationRequests.data"
        :item="item"
        :key="index"
      />
    </div>
  </div>
  <EmailVerifierInput />
</template>

<script>
import store from "@/store";
/*import Datatable from "@/components/kt-datatable/KTDatatable.vue";*/
import EmailVerifierInput from "@/components/modals/forms/EmailVerifierInputOption.vue";
import EmailVerificationRequest from "@/components/cards/EmailVerificationRequest.vue";

export default {
  name: "EmailVerifier",
  components: { EmailVerifierInput, EmailVerificationRequest },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    emailVerificationRequests() {
      console.log("computed");
      return store.getters["EmailVerifier/getEmailVerificationRequestList"];
    },
    apiUrl() {
      return process.env.VUE_APP_API_HOST;
    },
  },
  created() {
    console.log("created");
    this.getEmailVerificationRequests();
    store.dispatch("setBreadcrumbAction", { title: "Email Verifier" });
  },
  methods: {
    getEmailVerificationRequests() {
      store.dispatch("EmailVerifier/getEmailVerificationRequestList");
    },
    /* rowsPerPageChange(perPage) {
      this.pagination.per_page = perPage;
    },
    pageChange(page) {
      this.pagination.page = page;
    },*/
  },
  watch: {
    pagination: {
      handler() {
        this.getEmailVerificationRequests();
      },
      deep: true,
    },
  },
  /*  methods: {
      getScrapes() {
        const queryString = Object.keys(this.pagination).map(key => key + "=" + this.pagination[key]).join("&");
        store.dispatch("SiteScraper/getScrapes", queryString);
      },
      rowsPerPageChange(perPage) {
        this.pagination.per_page = perPage;
      },
      pageChange(page) {
        this.pagination.page = page;
      }
    }*/
};
</script>

<style scoped></style>
