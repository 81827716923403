<template>
  <div id="chart">
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "DonutChart",
  props: ["item"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        this.item.valid_emails,
        this.item.accept_all_emails,
        this.item.unknown_emails,
        this.item.disposable_emails,
        this.item.invalid_emails,
      ],
      chartOptions: {
        chart: {
          type: "donut",
        },
        labels: [
          "Valid Emails",
          "Accept All Emails",
          "Unknown Emails",
          "Disposable Emails",
          "Invalid emails",
        ],
        legend: {
          show: true,
          position: "top",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      /*    return {
      /!*      id: 1,
            name: "TestList",
            status: "completed",
            total_emails: 100,
            complete_emails: 99,
            duplicate_emails: 1,
            valid_emails: 78,
            accept_all_emails: 3,
            disposable_emails: 4,
            unknown_emails: 10,
            invalid_emails: 5,
            file_location: "notfound",
            created_at: "2023-03-16 22:47:17"*!/
    };*/
    };
  },
  methods: {},
};
</script>
